import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";

function Layout({ indexPage, children }) {
  const data = useStaticQuery(graphql`
    {
      resume: file(relativePath: { eq: "SaraAlnasserCV.pdf" }) {
        publicURL
      }
    }
  `);
  return (
    <div className="h-screen flex flex-col justify-between items-center pt-12">
      <div className="w-full flex justify-between items-center px-5 md:px-20">
        <Link to="/" className="no-underline" activeClassName="active">
          <h1 className={getClassName(indexPage)}>Sara Alnasser</h1>
        </Link>
        <a
          href={data.resume.publicURL}
          className="no-underline bg-blue hover:bg-blue-dark py-2 px-4 rounded"
          target="_blank"
          rel="noreferrer"
        >
          <span className="text-white text-base md:text-lg">Résumé</span>
        </a>
      </div>
      {children}
    </div>
  );
}

function getClassName(indexPage) {
  const className = "text-black text-base md:text-lg uppercase font-normal";

  return indexPage ? className : `${className} text-grey-darker`;
}

Layout.defaultProps = {
  indexPage: false,
};

export default Layout;
