import React from "react";
import { Link } from "gatsby";
import { useTrail, animated } from "react-spring";

function whichTrail(indexPage, navIndex) {
  return indexPage ? navIndex + 1 : navIndex;
}

function ButtomNav({ setProjectIndex, indexPage }) {
  const trail = useTrail(indexPage ? 6 : 5, {
    opacity: 1,
    from: { opacity: 0 },
  });

  const projectLinkClassName =
    "no-underline text-black tracking-normal hover:underline sm:text-center";

  return (
    <ul className="flex flex-col md:flex-row items-center flex-wrap mb-6 list-reset text-sm md:text-lg">
      {indexPage ? null : (
        <animated.li style={trail[0]} className="mx-8 my-2">
          <Link
            to="/"
            className={projectLinkClassName}
            activeClassName="active"
          >
            Home
          </Link>
        </animated.li>
      )}
      <animated.li
        style={trail[whichTrail(indexPage, 0)]}
        onMouseEnter={() => setProjectIndex(2)}
        onMouseLeave={() => setProjectIndex(0)}
        className="mx-8 my-2"
      >
        <Link
          to="/evie"
          className={projectLinkClassName}
          activeClassName="active"
        >
          Evie
        </Link>
      </animated.li>
      <animated.li
        style={trail[whichTrail(indexPage, 1)]}
        onMouseEnter={() => setProjectIndex(3)}
        onMouseLeave={() => setProjectIndex(0)}
        className="mx-8 my-2"
      >
        <Link
          to="/uoft_acc"
          className={projectLinkClassName}
          activeClassName="active"
        >
          Accessibility Services Office
        </Link>
      </animated.li>
      <animated.li
        style={trail[whichTrail(indexPage, 2)]}
        onMouseEnter={() => setProjectIndex(1)}
        onMouseLeave={() => setProjectIndex(0)}
        className="mx-8 my-2"
      >
        <Link
          to="/spacejam"
          className={projectLinkClassName}
          activeClassName="active"
        >
          Space Jam
        </Link>
      </animated.li>
      <animated.li
        style={trail[whichTrail(indexPage, 3)]}
        onMouseEnter={() => setProjectIndex(4)}
        onMouseLeave={() => setProjectIndex(0)}
        className="mx-8 my-2"
      >
        <Link
          to="/photography"
          className={projectLinkClassName}
          activeClassName="active"
        >
          Photography
        </Link>
      </animated.li>
      <animated.li
        style={trail[whichTrail(indexPage, 4)]}
        onMouseEnter={() => setProjectIndex(5)}
        onMouseLeave={() => setProjectIndex(0)}
        className="mx-8 my-2"
      >
        <Link
          to="/contact"
          className={projectLinkClassName}
          activeClassName="active"
        >
          Contact
        </Link>
      </animated.li>
    </ul>
  );
}

ButtomNav.defaultProps = {
  setProjectIndex: function () {},
  indexPage: false,
};

export default ButtomNav;
